import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { reducers, effects, featureKey, metaReducers } from './store';

export function ProvidePartners(): EnvironmentProviders {
  return makeEnvironmentProviders([
    provideState(featureKey, reducers, {
      metaReducers: metaReducers,
    }),
    provideEffects(effects),
  ]);
}
